@use "@carbon/styles/scss/config" with (
	// Use flexbox for grid - stick to CSS Grid or Flexbox
	// CSS Grid has become the default grid system in v11
	$use-flexbox-grid: true
);

@use "@carbon/styles";

.white {
	@include styles.theme(styles.$white);
}

.g10 {
	@include styles.theme(styles.$g10);
}

.g90 {
	@include styles.theme(styles.$g90);
}

.g100 {
	@include styles.theme(styles.$g100);
}


// Want to use only one theme? Add the css variables directly in root

// body {
// 	@include styles.theme(styles.$white);
// }

main {
	padding: 0 !important;
	padding-top: 47px !important;
}

.cds-button-set {
	margin-top: 16px !important
}

//.cds--header__name--prefix {
//	display: none !important;
//}

.cds--header__nav {
	display: flex !important;
}

.cds--header__menu-item {
	border: none !important;
}

.tiles-row {
	display: flex;
	justify-content: space-between;
	gap: 16px; /* Add gap between tiles */
	margin-bottom: 16px;
	padding-top: 8px;
	overflow-y: scroll;
}

cds-tile {
	width: calc((100vw - 64px - 48px) / 3) !important;
	min-width: 200px !important;
}

.cds--table-column-checkbox {
	display: none !important;
}

cds-button-set {
	padding-top: 8px
}

tr {
	margin-left: 16px;
}

.cds--date-picker__input {
	width: calc(100vw - 64px) !important;
}

@media (max-width: 600px) {
  .cds--header__name {
    display: none !important;
  }
}
